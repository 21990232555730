export const environment = {
  production: false,

  msalInstaceConfiguration: {
    clientId: 'e6480ef9-4ce6-4e0c-842b-27478db1796a',
    authority: 'https://login.microsoftonline.com/889ece36-85c6-46c8-a7bd-b9c04d57ddb2',
    redirectUri: '/',
    postLogoutRedirectUri: '/'
  },

  msalInterceptionConfigFactory: {
    graphMicrosoft: 'https://graph.microsoft.com/v1.0/me',
    urlApiTimpApp: 'https://aps-app-timp-backend-bs-dev.azurewebsites.net/**',
    scopeTimpApp: 'api://e6480ef9-4ce6-4e0c-842b-27478db1796a/access_as_user',
    urlApiTesteTimpApp: 'http://localhost:8080/**',
  },

  apiUrlBase: 'https://aps-app-timp-backend-bs-dev.azurewebsites.net/api',
  apiUrlBaseInforme: 'https://aps-app-timp-rendimentos-api-bs-dev.azurewebsites.net/api',
  versao: '0.0.7-DEV'
};
