import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from "src/environments/environment";

import { FiltroInformeRendimentos, InformeRendimentosList, RendimentosCnpjDTO, RendimentosCpfDTO } from '../domain/informe';



@Injectable()
export class InformeService {

    constructor(private http: HttpClient) { }

    getInformeRendimentos(filtroInformeRendimentos: FiltroInformeRendimentos) {

        let url = `${env.apiUrlBase}/v1/informe/rendimentos`;
        let controleUrl = url.length;
        let isCnpj = filtroInformeRendimentos.cpfCnpj.length === 14;

        if (filtroInformeRendimentos.cpfCnpj !== null && filtroInformeRendimentos.cpfCnpj !== undefined) {
            url = url.concat(`${url.length === controleUrl ? '?' : '&'}cpf_cnpj=${filtroInformeRendimentos.cpfCnpj}`);
        }
        if (filtroInformeRendimentos.anoCalendario !== null && filtroInformeRendimentos.anoCalendario !== undefined && filtroInformeRendimentos.anoCalendario.toString().trim() !== '') {
            url = url.concat(`${url.length === controleUrl ? '?' : '&'}ano_vigente=${filtroInformeRendimentos.anoCalendario.getFullYear()}`);
        }

        if (isCnpj) {
            return this.http.get<RendimentosCnpjDTO>(`${url}`)
                .toPromise()
                .then(res => res as RendimentosCnpjDTO)
                .then(data => data);
        } else {
            return this.http.get<RendimentosCpfDTO>(`${url}`)
                .toPromise()
                .then(res => {
                    console.log('res ' + res.ano_calendario);
                    return res as RendimentosCpfDTO;
                })
                .then(data => data);
        }
    }

    downloadReport(filtroInformeRendimentos: FiltroInformeRendimentos) {
        let url = `${env.apiUrlBase}/v1/informe/proxy-download?cpfBeneficiario=${filtroInformeRendimentos.cpfCnpj}&anoCalendario=${filtroInformeRendimentos.anoCalendario.getFullYear()}`;
        return this.http.get(`${url}`, { responseType: 'blob' })
    }
}